import { PropsWithChildren } from 'react';
import { DuAuthenticationUtilities } from '../legacy/DriscollsReactUtilities';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '@driscollsinc/login-module-ui';
import { DrcPageNotAuthorized } from '../legacy/DriscollsReactComponents';

interface GroupBaseProtectProps {
    groupsAllowed: any[];
    businessUnit?: 'dota' | 'demea';
}

const GroupBaseProtect = (props: PropsWithChildren<GroupBaseProtectProps>) => {
    const { children, groupsAllowed, businessUnit = 'dota' } = props;
    const { authState } = useAuth();
    const { pathname } = useLocation();

    const isAuthorized = () => {
        if (pathname.includes('demea') && businessUnit === 'dota') {
            return false;
        }

        if (!authState || !authState?.isAuthenticated) {
            return <Navigate to={'/'} />;
        } else {
            const tokenGroups = DuAuthenticationUtilities.GetGroups(authState?.accessToken) || [];
            const requiresGroup = (groupsAllowed || []).length > 0;
            return requiresGroup ? tokenGroups.some((r) => groupsAllowed.indexOf(r) >= 0) : true;
        }
    };

    return isAuthorized() ? children : <DrcPageNotAuthorized authState={authState} />;
};

export default GroupBaseProtect;
