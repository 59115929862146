import { DriscollsImplicitCallback } from '@driscollsinc/login-module-ui';
import { routes, routesName } from '../../demea/routes/routes';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

const QMPLoginCallback = () => {
    const location = useLocation();
    const navigate = useNavigate();

    return <DriscollsImplicitCallback redirectUrl={routes[routesName.HOME].path} Navigate={Navigate} navigate={navigate} location={location} />;
};

export default QMPLoginCallback;
